import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="divine-emissary"></a><h3>DIVINE EMISSARY</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44278_C1_divineemissary.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d10.
    <a id="divine-emissary-requirements"></a><h6>Requirements</h6>
To become a divine emissary, the character must fulfill all
the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Base Attack Bonus:</span>
+23. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a> (deity&#8217;s favored weapon). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feat:</span> <a href="epicFeats.html#great-smiting" style={{
        "color": "rgb(87, 158, 182)"
      }}>Great
Smiting</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(religion) 10 ranks. </p>
    <p>Special: Must have a patron deity. Furthermore, the potential
divine emissary must complete some quest that furthers his or her
deity&#8217;s goals so much that it impresses the deity.</p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The divine emissary&#8217;s class skills (and the key ability for
each) are: <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a>
(Cha), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a>
(Wis), <a href="skillsAll.html#intimidate" style={{
        "color": "rgb(87, 158, 182)"
      }}>Intimidate</a> (Cha), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(religion) (Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a> (Wis), <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
(Int), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> (Wis), <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
(Int), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
(Wis), and <a href="skillsAll.html#use-magic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Magic Device</a> (Cha). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
4 + Int modifier. </p>
&nbsp;
    <a id="table-the-divine-emissary"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Divine Emissary</span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Divine inspiration</span> 1/day, granted
domain
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Extra
smite 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Greater planar ally</span> 1/day</td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Divine inspiration</span> 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Extra
smite 3/day</td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Divine inspiration</span> 3/day</td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Extra
smite 4/day</td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Divine
hand 1/day</td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Divine
inspiration</span> 4/day</td>
        </tr>
      </tbody>
    </table>
    <a id="divine-emissary-class-features"></a><h6>Class Features</h6>
All the following are features of the divine emissary prestige class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells per Day/Spells Known:</span>
At each divine emissary level, the character gains new spells per day
(and spells known, if applicable) as if he or she had also gained a
level in a spellcasting class to which he or she belonged before adding
the prestige class level. If already an epic spell-caster, the
character gains only the benefit noted under the Spells entry for that
epic class. He or she does not, however, gain any other benefit a
character of that class would have gained. If the character had more
than one spellcasting class before becoming a divine emissary, the
player must decide to which class to add the new level for the purpose
of determining spells per day. </p>
    <a id="divine-emissary-special-mount"></a><p><span style={{
        "fontWeight": "bold"
      }}>Special Mount:</span> If he
or she has one, the divine emissary&#8217;s special mount continues to
increase in power. Every five levels after 1st, the special mount gains
+2 bonus Hit Dice, its natural armor increases by +2, its Strength
adjustment increases by +1, and its Intelligence increases by +1. The
mount&#8217;s spell resistance equals the divine emissary&#8217;s class level + the
class level that provided the special mount + 5. </p>
    <a id="divine-emissary-granted-domain"></a><p><span style={{
        "fontWeight": "bold"
      }}>Granted Domain (Ex):</span> A
divine emissary gains access to one of his or her deity&#8217;s domains, as
well as the granted power of that domain. The extra domain expands a
paladin&#8217;s selection of spells, but he or she does not gain the ability
to cast higher-level spells than he or she otherwise could. Clerics
gain an additional domain but otherwise use the rules for preparing
spells from their domains normally. </p>
    <a id="divine-emissary-divine-inspiration"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Divine
Inspiration (Sp):</span> A divine emissary gains a +2 luck bonus on his
or her attack and damage rolls for 10 rounds, once per day at 1st
level, plus one additional time per day every three levels thereafter. </p>
    <a id="divine-emissary-extra-smite"></a><p><span style={{
        "fontWeight": "bold"
      }}>Extra Smite (Su):</span> A
divine emissary can use his or her smite ability two extra times per
day, plus one additional time per day every three levels thereafter. To
determine the damage with any smite attack, a divine emissary adds
together his or her divine emissary levels and class levels that
originally conferred the smite ability. </p>
    <p><span style={{
        "fontWeight": "bold",
        "fontStyle": "bold"
      }}>Greater
Planar Ally (Sp):</span> The emissary can call a greater planar ally
(as the spell) once per day at 3rd level, plus one additional time per
day every ten levels thereafter. The ally does not request a return
favor when a divine emissary uses this ability. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The divine
emissary gains a bonus feat at 6th level and every 10
levels thereafter. These bonus feats must be selected
from the following list: Armor Skin, Devastating Critical, Epic
Leadership, Epic Prowess, Epic Reputation, Epic Toughness, Epic Weapon
Focus, Great Smiting, Holy Strike, Improved Aura of Courage, Improved
Combat Casting, Improved Spell Capacity, Legendary Commander, Legendary
Rider, Overwhelming Critical, Perfect Health, Permanent Emanation,
Planar Turning, Positive Energy Aura, Spectral Strike, Spontaneous
Spell, Widen Aura of Courage. </p>
    <a id="divine-emissary-divine-hand"></a><p><span style={{
        "fontWeight": "bold"
      }}>Divine Hand (Su):</span> As a
free action, the emissary gains a +20 sacred (or profane if
appropriate) bonus on his next melee or ranged attack roll, as long as
the attack is made with the deity&#8217;s favored weapon. The emissary can
use divine hand once per day at 9th level, plus one additional time per
day every ten levels thereafter.</p>
    <p> </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      